 

@font-face {
  font-family: loewmedium;
  src: url('./fonts/loew-medium-webfont.woff') format('woff'),
  url('./fonts/loew-lightitalic-webfont.woff2') format('woff2');
}


@font-face {
  font-family: loewbold;
  src: url('./fonts/loew-bold-webfont.woff') format('woff'),
  url('./fonts/loew-bold-webfont.woff2') format('woff2');
}

@font-face {
  font-family: loewblack;
  src: url('./fonts/loew-black-webfont.woff');
}

@font-face {
  font-family: loewheavy;
  src: url('./fonts/loew-heavy-webfont.woff');
}

.rounded {
  /* border-radius: .35rem!important; */
}

html , body{
  position: relative;
  overflow-x: hidden !important;
  width: 100%;
}

body, .body-class{
font-size: 13px;
font-family: 'loewmedium' !important;
position: relative;
scroll-behavior: smooth;
}
.text-default-color{
color: #b41502;
}

/***********************************************************
  BANNER STYLES
***********************************************************/
.btn:active,
.btn.focus,
.btn:focus,
.form-control:focus{
box-shadow: none !important;
}
.h4, h4{font-size: 1.7rem;}
.o2e-header-wrapper{
background-color: #EC2134;
  background-image: url(./images/banner-bg.png);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  
}
.o2e-header{
padding: 40px 0px;
}
.o2e-banner{
min-height: 350px;
}
.o2e-banner h2{
font-size: 35px;
}
.o2e-banner h5{
font-family: 'loewmedium';
color: #e8e8e8;
font-weight: normal;
font-size: 19px;
}
.search-location .select-menu,
.search-location .form-control{
  color: #2c2c2c;
  font-weight: 500;
  line-height: 2.5;
  height: calc(2.5em + 1rem + 2px);
  border: none;
}
.select-menu{
  background: #FFF url(./images/selectbox-arrow.png) no-repeat right 1.2rem center;
  background-size: 11px;
}
.menu-seprator{
position: relative;
}
.menu-seprator:before{
content: "";
width: 1px;
position: absolute;
top: 10px;
right: -3px;
bottom: 10px;
display: block;
background-color: #818181;
}
.btn-submit{
color: #2f2b2a;
border: none;
height: 58px;
font-weight: 600;
    background: #FCC717;
/*background: rgb(255,158,0);
background: -moz-linear-gradient(left,  rgba(255,158,0,1) 0%, rgba(249,241,0,1) 40%, rgba(249,241,0,1) 65%, rgba(255,158,0,1) 100%);
background: -webkit-linear-gradient(left,  rgba(255,158,0,1) 0%,rgba(249,241,0,1) 40%,rgba(249,241,0,1) 65%,rgba(255,158,0,1) 100%);
background: linear-gradient(to right,  rgba(255,158,0,1) 0%,rgba(249,241,0,1) 40%,rgba(249,241,0,1) 65%,rgba(255,158,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9e00', endColorstr='#ff9e00',GradientType=1 );*/
}
.btn-bg{
background: #bd2130;
/*background: -moz-linear-gradient(left,  #ab2c10 0%, #ed5839 100%);
background: -webkit-linear-gradient(left,  #ab2c10 0%,#ed5839 100%);
background: linear-gradient(to right,  #ab2c10 0%,#ed5839 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ab2c10', endColorstr='#ed5839',GradientType=1 );*/
}
/*.btn-submit:focus,
.btn-submit:active{
color: #FFF;
background: #ab2c10;
}*/
/***********************************************************
  BANNER STYLES
***********************************************************/
.section{
padding-top: 4rem;
padding-bottom: 4rem;
}
.divider {
  width: 150px;
  height: 3px;
  background: #e1e1e1;
  margin: 0 auto;
  border-radius: 5px;
}
.inner-divider {
  width: 75px;
  height: 3px;
  background: #e54750;
  margin: 0 auto;
}
.section-heading-left .divider{
margin-left: 0px;
}
#o2ePCarousel .owl-cat-item {
  height: 262px !important;
}
#o2ePCarousel .owl-item .owl-cat-img{
width: auto;
height: 100%;
}
#o2ePCarousel .owl-cat-content{
top: 0px;
right: 0px;
bottom: 0pc;
left: 0pc;
position: absolute;
}
#o2ePCarousel .owl-upper-text {
  background: white;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 18px;
  text-align: center;
}
#o2ePCarousel .owl-down-text{
background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 );
}
#o2ePCarousel .owl-down-text h4,
#o2ePCarousel .owl-down-text p{
color: #FFF;
margin: 0px;
line-height: 22px;
}
#o2ePCarousel .owl-down-text h4{
font-size: 22px;
font-weight: 600;
}
#o2ePCarousel .owl-down-text p{
font-size: 14px;
}
#o2ePCarousel .owl-nav.disabled {
  display: block;
}
#o2ePCarousel .owl-nav button.owl-next,
#o2ePCarousel .owl-nav button.owl-prev{
position: absolute;
top: 50%;
width: 44px;
height: 44px;
display: block;
margin-top: -20px;
border-radius: 44px;
background-color: #FFF;
-webkit-box-shadow: 1px 1px 3px #ccc;
-moz-box-shadow: 1px 1px 3px #ccc;
-o-box-shadow: 1px 1px 3px #ccc;
box-shadow: 1px 1px 3px #ccc;
-ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}
#o2ePCarousel .owl-nav button.owl-next:focus,
#o2ePCarousel .owl-nav button.owl-prev:focus{
-webkit-box-shadow: 1px 1px 3px #ccc !important;
-moz-box-shadow: 1px 1px 3px #ccc !important;
-o-box-shadow: 1px 1px 3px #ccc !important;
box-shadow: 1px 1px 3px #ccc !important;
}

#o2ePCarousel .owl-nav button.owl-next{
right: -20px;
}
#o2ePCarousel .owl-nav button.owl-prev{
left: -20px;
}
.section-heading .title{
color: #161616;
font-weight: 600;
font-size: 2.3rem;
}
.section-heading .secondary-title{
color: #444444;
font-weight: 500;
font-size: 1.3rem;
}
.section-heading.section-heading-white .title,
.section-heading.section-heading-white .secondary-title{
color: #FFF;
}
.o2e-trr{
background-color: #f9fafb;
}
.view-all-btn{
min-width: 150px;
}
.view-all-btn .btn{
font-weight: 600;
font-size: 1.1rem;
padding-right: 35px;
background: url(./images/arrow-danger.png) no-repeat right center;
}
.o2e-badge{
padding: .35rem .8rem;
}
.sale-off{
margin-left: auto;
background-color: #f3723b !important;
}
.o2e-trr-bottom h4,
.o2e-trr-bottom p{
color: #FFF;
margin: 0px;
line-height: 22px;
}
.o2e-trr-bottom h4{
font-size: 22px;
font-weight: 600;
}
.o2e-trr-bottom p{
font-size: 14px;
}
.o2e-delivery-option span,
.o2e-rating .rating-number{
color: #595959;
font-weight: 600;
}
.o2e-rating i.text-success{
color: #67be62 !important;
}
.o2e-card .card-img {
  height: 170px;
}
.o2e-cmp{
background-image: url(./images/choose-most-popular.jpg);
background-repeat: no-repeat;
background-size: cover;
}
.o2e-cmp.bg-image{
position: relative;
}
.o2e-cmp.bg-image:before{
background-color: #3c485e80;
}
.o2e-cmp.bg-image:before{
content: "";
position: absolute;
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
z-index: 1;
}
.bg-image > div{
position: relative;
z-index: 2;
}
#o2e-cmp-carousel{
margin-top: -70px;
padding: 0px 50px;
}
#o2e-cmp-carousel .o2e-cmp-item{
padding: 0;
background-color: #FFF;
border-radius: 0px;
margin-bottom: 15px;
-webkit-box-shadow: 2px 2px 5px #ccc;
-moz-box-shadow: 2px 2px 5px #ccc;
-o-box-shadow: 2px 2px 5px #ccc;
box-shadow: 2px 2px 5px #ccc;
}


#o2e-cmp-carousel .owl-item img {
  width: 100% !important;
  margin: 0px auto;
}
#o2e-cmp-carousel .owl-nav.disabled {
  display: block;
}
#o2e-cmp-carousel .owl-nav button.owl-next,
#o2e-cmp-carousel .owl-nav button.owl-prev{
position: absolute;
top: 50%;
width: 44px;
height: 44px;
display: block;
margin-top: 0px;
border-radius: 0px;
background-color: transparent;

-ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}
#o2e-cmp-carousel .owl-nav button.owl-next{
right: -10px;
}
#o2e-cmp-carousel .owl-nav button.owl-prev{
left: -10px;
}
.o2e-fdo .section-heading .title{
color: #ed1c31;
font-size: 52px;
font-weight: 700;
}
.o2e-fdo .section-heading p{
font-size: 18px;
color: #161616;
}
.o2e-fdo .section-heading .secondary-title{
color: #161616;
font-size: 30px;
font-weight: 600;
}
#download-background {
  position: absolute;
  bottom: -40px;
  z-index: -99;
  left: -80px;
  width: 86%;
}
.footer-main{
padding-top: 5rem;
padding-bottom: 3rem;
background-color: #ed1c31;
background-size: contain;
background-repeat: no-repeat;
background-position: center top;
background-image: url("./images/footer-bg.jpg");
}
.widget-title{
color: #ffc916;
font-size: 20px;
text-transform: uppercase;
}
.widget-nav .nav-item .nav-link{
color: #FFF;
}
.widget-nav.contact-widget .nav-item{
color: #FFF;
padding-top: .25rem;
padding-bottom: .25rem;
}
.company-number{
color: #ffc916;
font-weight: 600;
font-size: 18px;
}
.footer-bottom{
background-color: #FFFFFF;
}
.footer-bottom p{
color: #2f2b2a;
margin: 0px;
}

/**************************************************
  DASHBOARD STYLES
**************************************************/
.db-page-header{
background-image: none;
background-color: #FFF;
padding: 10px 0px !important;
}
.btn-feel-eating,
.btn-choose-loc{
font-size: 13px;
background-repeat: no-repeat;

}
.btn-feel-eating{
background-position: 0px center;
background-image: url(./images/direction-circle.png);
}
.btn-choose-loc{
background-position: 8px center;
background-image: url(./images/header-map.png);
}
.header-nav .nav > li a{
position: relative;
}
.header-nav .nav > li a:after{
content: "";
top: 0px;
right: 0px;
bottom: 0px;
width: 1px;
height: 20px;
margin: auto 0;
position: absolute;
border-right: 2px solid #1b1b1b;
}
.header-nav .navbar-nav > li:last-child a:after{
display: none;
}
.header-nav .navbar-nav > li > a{
font-size: 13px;
color: #1b1b1b;
}
.header-nav .navbar-nav > li.o2e-user-admin > a{
color: #dd0303;
}
.cart-wrapper{
  width: 65px;
  height: 50px;
  color: #FFF;
  background-color: #ffcf3a;
  background-repeat: no-repeat;
  background-position: 10px center;
  background-image: url("./images/addtocart-icon.png");
}
.cart-count{
padding-left: 2rem;
}
.navbar-toggler{
height: 50px;
}
.inner-page-header{
min-height: 164px !important;
background-image: url(./images/inner-header-image.jpg);
background-repeat: no-repeat;
background-size: cover;
}
.o2e-user-profile{
margin-top: -40px;
}
.o2e-user-profile .avatar-img{
border: 8px solid #FFF;
}
.o2e-rating-txt{

}
.o2e-rating-txt strong{
font-family: 'loewbold';
}
.o2e-user-location{
color: #343233;
padding: 3px 3px 3px 20px;
background: url(./images/dark-map-icon.png) no-repeat left center;
}
.o2e-menu-tabs{
overflow: hidden;
background-color: #f9fafb;
border: 1px solid #e2e6e9;
}
.o2e-menu-tabs .nav-pills > .nav-item.menu-item{
-ms-flex-align: center;
align-items: center;
-ms-flex-pack: center;
justify-content: center;
background-color: #ffcf3a;
font-family: 'loewbold';
padding: 1rem;
}
.o2e-menu-tabs .nav-pills > .nav-item{
display: flex;
font-size: 14px;
color: #2f2e2e;
}
.o2e-menu-tabs .nav-pills > .nav-item > .nav-link{
color: #2f2e2e;
display: flex;
-ms-flex: 1 1 auto;
flex: 1 1 auto;
-ms-flex-align: center;
align-items: center;
-ms-flex-pack: center;
justify-content: center;
padding: 1rem;
position: relative;
}
.o2e-menu-tabs .nav-pills > .nav-item > .nav-link:hover,
.o2e-menu-tabs .nav-pills > .nav-item > .nav-link:focus,
.o2e-menu-tabs .nav-pills > .nav-item > .nav-link:visited,
.o2e-menu-tabs .nav-pills > .nav-item > .nav-link.active{
color: #2f2e2e;
background-color: #ffcf3a;
font-family: 'loewbold';
}
.o2e-menu-tabs .nav-pills > .nav-item > .nav-link:after{
content: "";
top: 6px;
right: 0px;
bottom: 6px;
width: 1px;
margin: auto 0;
position: absolute;
border-right: 1px solid #dedede;
}
.o2e-menu-tabs .nav-pills > .nav-item:last-child > .nav-link:after{
display: none;
}
.o2e-menu-tabs .nav-pills > .nav-item:not(:last-child) > .nav-link{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.o2e-menu-tabs .nav-pills > .nav-item:not(:first-child) > .nav-link,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.vertical-nav-pills{
width: 180px;
}
.vertical-nav-pills .nav-link{
height: 45px;
padding: 0rem 1rem;
font-family: 'loewmedium';
background-color: #ede7e9;
margin-bottom: 7px;
color: #2f2e2e;
display: flex;
-webkit-transition: all ease-in-out .1s;
-moz-transition: all ease-in-out .1s;
-o-transition: all ease-in-out .1s;
transition: all ease-in-out .1s;
-ms-flex-align: center;
align-items: center;
}
.vertical-nav-pills .nav-link:last-child{
margin-bottom: 0px;
}
.nav-pills .nav-link.active,
.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus,
.nav-pills .show > .nav-link{
color: #000000;
background-color: #ffcf3a;
}
.o2e-tabsmenu-section{
padding-top: 2rem;
padding-bottom: 2.5rem;
}
.o2e-show-categories,
.o2e-show-categories:hover,
.o2e-show-categories:focus{
  top: 50% !important;
  left: -47px !important;
  position: fixed;
  color: #FFF !important;
  margin-top: 0px !important;
  background-color: #b41502 !important;
  -ms-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important; 
  font-size: 10px !important;
  border-bottom-left-radius: 0px !important;
border-bottom-right-radius: 0px !important;
}
.o2e-closemenu-btn,
.o2e-closemenu-btn:hover,
.o2e-closemenu-btn:focus{
  /*top: 15px;
  right: 15px;
  position: absolute;*/
  color: #FFF;
  background-color: #b41502;
  font-size: 14px;
  height: 35px;
  width: 35px;
  border: 2px solid #ffc713;
}
.o2e-closemenu-btn.btn-mobileview,
.o2e-closemenu-btn.btn-mobileview:hover,
.o2e-closemenu-btn.btn-mobileview:focus{
  top: 15px;
  right: 15px;
  position: fixed;
  color: #FFF;
  background-color: #b41502;
  font-size: 14px;
  height: 35px;
  width: 35px;
  border: 2px solid #b41502;
}
.o2e-closemenu-btn.btn-mobileview svg{
width: 29px;
height: 32px;
}
.o2e-closemenu-btn svg{
width: 29px;
height: 35px;
}
.o2e-searchmenu-wrapper .form-control-lg,
.o2e-searchmenu-wrapper .btn-lg{
font-size: .8rem;
}
.o2e-searchmenu-wrapper .form-group > .form-control:not(textarea),
.o2e-searchmenu-wrapper .form-group > .btn-lg{
    height: calc(2.8em + 1rem + 2px);
}
.o2e-search-input{
padding-left: 2.7rem;
background-image: url(./images/search.svg);
background-repeat: no-repeat;
background-position: 15px center;
}
.o2e-search-input.form-control:focus{
border-color: #b4150250;
}
.search-btn{
top: 0px;
right: 0px;
position: absolute;
color: #FFF;
padding-left: 2.5rem !important;
padding-right: 2.5rem !important;
}
.menu-content-header {

}
.o2e-row{
background-color: #fdfdfd;
/*border: 1px solid #e2e3e2;*/
}
.o2emenucart-btn{
  width: 35px;
  height: 35px;
  background-image: url(./images/plus-symbol-button.png);
  background-repeat: no-repeat;
  background-position: center center;
  }
  
  .o2emenucart-btn-black{
    width: 35px;
  height: 35px;
  background-image: url(./images/plus-symbol-button\ \(2\).png);
  background-repeat: no-repeat;
  background-position: center center;
  }
.o2emenu-name{
/*width: 58%;*/
}
.o2emenu-name .title{
margin-top: .4rem;
font-size: 16px;
margin-bottom: 0px;
font-family: 'loewmedium';
}
.o2emenu-name .desc-text{
margin: 0px;
}
.d-a{
  display: flex !important; 
}
.h-card{
  height: 290px; 
}
.ml-35{
  margin-left: 35px;
}
.ml-10{
  margin-left: 10px;
}

.m-0{
  margin: 0 !important;
}
/**************************************************
  SIDEBR
**************************************************/
.widget-bg .bg-red{
background-color: #b41502;
}
.widget-bg .bg-yellow{
background-color: #ffcd39;
}
.widget-bg .bg-red.o2e-widget-title{
color: #FFF;
}
.widget-bg .bg-yellow.o2e-discount-text{
color: #000000;
}
.sidebar .o2e-card-header{
border-top-right-radius: .2rem;
border-top-left-radius: .2rem;
}
.o2e-discount-text,.o2e-changepasscode{
font-size: .9rem;
}
.o2e-cart-content{
background-color: #f9f9f9;
border-top-right-radius: 24px;
border-top-left-radius: 24px;
}
.o2e-changepasscode{

}
.o2e-changepasscode .link{
color: #b41502;
}
.o2e-deliveryoprion{
background-color: #e2e1dd;
}
.o2e-deliveryoprion .o2e-togglebtn{
font-size: .8rem;
line-height: 12px;
padding-left: 2rem;
text-align: left;
font-family: 'loewbold';
}
.o2e-deliveryoprion .o2e-togglebtn small{
font-size: 75%;
font-family: 'loewmedium';
}
.o2e-deliveryoprion .o2e-togglebtn > input[type="radio"]{
  left: 10px;
  top: 50%;
  margin-top: -6.4px;
  clip: inherit;
}
.o2e-togglebtn:not(:disabled):not(.disabled).active,
.o2e-togglebtn:not(:disabled):not(.disabled):active,
.show > .o2e-togglebtn.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.o2ecart-item{
background-color: #e2e1dd;
}
.btn-o2eremove-item{
line-height: 8px;
}
.o2ecart-item-price,
.o2ecart-item-name{
font-size: 0.9rem;
}
.o2e-cart-total .o2e-cart-totaltext{
color: #5d5c5d;
font-size: 14px;
font-family: 'loewbold';
}
.o2e-cart-mastertotal{
background-color: #020003;
}
.o2e-cart-mastertotal .o2e-cart-totaltext{
color: #FFF;
font-size: 14px;
font-family: 'loewbold';
}
.o2e-placeorderbtn,
.o2e-clearcartbtn{
color: #FFF;
font-size: 14px;
font-family: 'loewmedium';
}
.o2e-footeralert{
color: #5d5c5d;
/*font-size: 12px;]*/
}
.o2emenu-name{
width: 55%;
}
.o2emenu-price{
width: 25%;
}
.o2emenu-addcart{
width: 20%;
}
.placeorder-rorm .form-control{
color: #5c5c5c;
font-size: 16px;
font-family: 'loewmedium';
background-color: #313131 !important;
}

.b-color{
  background-color: #313131 !important;
}
.b-color input{
  background-color: #313131 !important;
  color: #fff !important;
}
.placeorder-rorm .btn{
color: #181818;
font-size: 20px;
font-family: 'loewmedium';
background-color: #ffcf3a;
}
#o2eAddresspasscode.form-control{
height: calc(1.5em + .75rem + 2px);
}
.o2e-termscondition{
color: #838383;
font-size: 13px;
}
.o2e-termscondition .link{
color: #b41502;
font-family: 'loewbold';
}
.o2e-card {
background-color: #fdfdfd;
}
.o2e-card .card-title{
color: #b41502;
font-size: 20px;
font-family: 'loewbold';
}
.o2e-card p.dark{
color: #161616;
font-size: 15px;
}
.o2efoodallergy-card,
.o2epayment-card{
background-color: #f8f6f6 !important;
border-color: #f8f6f6 !important; 
}
.o2efoodallergy-card p{
color: #161616;
font-size: 15px;
}
.o2efoodallergy-card .btn-group > .btn.btn-secondary {
  color: #2f2b2a;
  font-size: 15px;
  background-color: #e2e1dd;
  border-color: #e2e1dd;
}
.o2efoodallergy-card .btn-group > .btn.btn-secondary.active {
  background-color: #ffc107;
  border-color: #ffc107;
}
.o2epayment-card .card-title{
color: #161616;
font-size: 24px;
font-family: 'loewblack';
}
.o2e-custom-select{
color: #2f2b2a;
font-size: 12px;
font-family: 'loewmedium';
height: calc(2em + 1rem + 2px);
position: relative;
max-width: 380px;
background: #fff url("./images/dropdown-bg.png") right no-repeat;
}
.o2e-custom-select option{
font-family: 'loewmedium';
}
.securepayment-card{
background-color: transparent;
border-color: transparent;
}
.securepayment-card .card-body{
max-width: 450px;
}
.securepayment-card .card-title{
color: #a9a9aa;
font-size: 25px;
font-family: 'loewmedium';
}
.securepayment-card p{
color: #a9a9aa;
font-size: 15px;
font-family: 'loewmedium';
}
.o2e-commentcard{
background-color: #f8f6f6 !important;
border-color: #f8f6f6 !important;
color: #010101 !important;
}
.o2e-commentcard .card-header .card-title{
color: #010101;
font-size: 20px;
}
.o2e-commentcard .btn{
font-size: 15px;
}
.o2e-feedabelly h4{
color: #4c4c4c;
font-size: 25px;
font-family: 'loewbold';
}
.o2e-feedabelly h3{
color: #010101;
font-size: 22px;
font-family: 'loewbold';
margin-bottom: 0px;
margin-top: 10px;
}
.o2e-feedabelly .link{
color: #f710ef;
font-size: 22px;
font-family: 'loewbold';
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
color: #6c757d;
}
.search-page-content .btn-feel-eating,
.search-page-content .btn-choose-loc{
color: #ec2134;
}
.o2e-dark-nav .nav-item .nav-link{
color: #1b1b1b;
font-family: 'loewbold';
}
/*.change-location span{
font-size: 12px;
font-family: 'loewbold';
line-height: 18px;
background: url(./images/dark-map-icon.png) no-repeat 0px center;
text-align: center;
color: white;
}*/


label {
  display: inline-block;
  /* margin-bottom: 1rem !important; */
}

.change-location span{
font-size: 11px;
font-family: 'loewbold';
line-height: 18px;
text-align: center;
color: white;

}

.change-location span i{
font-size: 17px;
  line-height: 20px;
  padding: 0px 5px 0px;
}
.change-location a{
color: #ffbe18;
  font-size: 10px;
  font-family: 'loewmedium';
  text-align: center;
  padding-left: 45px;
  font-weight: 600;
}
.search-page-content .inner-page-header{
background: #f5f5f5;
}

.new-search-page-content .search-bar {
background: #020202;
margin-top: 35px;
} 
.search-bar{
  background-color: black;
}
.search-item-form .btn-submit {
  color: #FFF;
  border: none;
  font-weight: 600;
  background: #ffbe18;
  /* position: absolute;
  right: 0;
  top: 0; */
}

.d-flex{
  display: flex;
}
.justify-space-around{
  justify-content: space-around;
}
.search-item-form .btn,
.search-item-form .form-control{
font-size: .8rem;
height: 42px;
}
.search-item-form .form-control{
padding-left: 2.9rem;
  /* background-image: url(./images/search.svg); */
  background-image: url(./images/sreach-white.png);
  background-repeat: no-repeat;
  background-position: 15px center;
}

.sidebar-heading h3{
color: #dc202f;
  font-size: 20px;
  font-family: 'loewbold';
  position: relative;
  line-height: 30px;
  background: #f8f6f6;
  padding: 10px 15px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.sidebar-heading .clearall{
font-size: 12px;
color: #9f9a9a;
text-decoration: none !important;
position: absolute;
  right: 5px;
  top: 10px;
  line-height: 22px;
  text-decoration: underline;
}
.sidebar-widget .title{
font-size: 24px;
font-family: 'loewmedium';
}
.sidebar-widget h3{
  color: #dc202f;
  /* color: #595959;
  background: #ffc613; */
}
.custom-checkbox.position-absolute{
  right: 15px;
  top: 10px;
}
.widget-form .form-control-label{
border: 1px solid #ced4da8f;
  /* background: #989ea538; */
  background: #ece8e7;
}
.custom-checkbox .custom-control-label::before{
border-radius: 2rem;
background: #000000;
border-color: #000000;
}
.black-check{
  border-radius: 50px !important;
  background: #000000 !important;
border-color: #000000 !important;
}

/* 
.black-check::before {
  border-radius: 2rem;
  background: #000000;
  border-color: #000000;
}

.black-check::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
} */

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.custom-control-label::after {
  position: absolute;
  /* top: 0rem; */
  top: 4px;
  left: -24px;
  /* left: -1rem; */
  display: block;
  /* width: 1rem; */
  width: 16px;
  height: 16px;
  /* height: 1rem; */
  content: "";
  background: 50%/50% 50% no-repeat;
}
.rounded {
  /* border-radius: 0.35rem!important; */
}
.position-absolute {
  position: absolute!important;
}
.rounded {
  /* border-radius: 0.25rem!important; */
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

}

.b-border{
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 5px;
}

p.blackk {
  font-size: 9px;
  transform: rotate(316deg);
  margin-top: 33px;
  margin-left: -13px;
  padding-bottom: 20px;
}
























.custom-checkbox.position-absolute .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #000000;
  background-color: #000000;
}
/*.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}*/
.custom-checkbox.position-absolute .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,0);
}
.content-header .title{
color: #000000;
font-size: 15px;
font-family: 'loewheavy';
}
.topcategory-item .card .card-title{
font-size: 20px;
font-family: 'loewbold';
}
div#topCategoryItem {
  margin-bottom: 12px !important;
}
#topCategoryItem .owl-nav.disabled {
  display: block;
  margin-bottom: 30px !important;
}
#topCategoryItem .owl-nav button.owl-next,
#topCategoryItem .owl-nav button.owl-prev {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  display: block;
  margin-top: -20px;
  border-radius: 44px;
  background-color: #FFF;
  -webkit-box-shadow: 1px 1px 3px #ccc;
  -moz-box-shadow: 1px 1px 3px #ccc;
  -o-box-shadow: 1px 1px 3px #ccc;
  box-shadow: 1px 1px 3px #ccc;

  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}
#topCategoryItem .owl-nav button.owl-next {
  right: -22px;
}
#topCategoryItem .owl-nav button.owl-prev {
  left: -22px;
}
.owl-theme .owl-nav{
margin-top: 0px;
}
.o2e-counts,
.o2e-menu-sorting{
font-size: 12px;
font-family: 'loewbold';
}
.o2e-menu-sorting .sort-toggle{
background-image: url(./images/filter-bar.png);
background-repeat: no-repeat;
background-position: center center;
}
.o23-filter-list {
  z-index: 1;
  top: 30px;
  background: #FFF;
  position: absolute;
  box-shadow: 1px 1px 4px #00000030;
  border-radius: .25rem;
}
.list-card-inner{
padding: .5rem 1em;
border-radius: .25rem;
}
.list-card-inner .custom-control-label{
color: #21252990;
line-height: 22px;
}
.list-card-inner .custom-control-label::before{
background-size: 1rem;
border: none;
border-color: transparent;
background-color: transparent;
background-image: url(./images/radio.png);
}
.list-card-inner .custom-control-input:checked ~ .custom-control-label::before {
background-image: url(./images/radio-checked.png);
/*background-color: #ffc916;
background-image: none;
border-radius: 30px;*/
}
.list-card-inner .custom-control-input:checked ~ .custom-control-label::before,
.list-card-inner .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
background-image: url(./images/radio-checked.png);
background-size: 1rem;
background-color: transparent;
border-radius: 30px;
text-shadow: none;
}
.list-card-inner .custom-control-input:focus ~ .custom-control-label::before {
box-shadow: none;
}
.sort-toggle:after{
display: none;
}
.o2e-menu-content .o2e-row{
background-color: white;
position: relative;
border-bottom: 1px solid #00000047;
/* margin-bottom: 35px; */
margin-bottom: 25px;
}
/* .h-card{
  height: 247px;
} */

.w-card{
  width: 813px;
}
.p-20{
  padding: 20px;
}
.mt-50{
  margin-top: -50px;
}


.o2e-menu-content .o2e-specialoffer{
top: 21px;
  left: -18px;
  width: 108px;
  color: black;
  text-align: center;
  position: absolute;
  background-color: #ffbe18;
  transform: rotate(-40deg);
  font-size: 10px;
  line-height: 20px;
  font-weight: bold;
}

.o2e-menu-content .o2emenu-name{
width: 100%;
padding-left: 35px;
}

.o2e-menu-content .o2emenu-name h3{
font-family: 'loewheavy';
  line-height: 36px;
  /* font-size: 26px; */
  /* color: #ec2134; */
  color: #dc202f;
}

.o2e-row ul{
list-style: none;
padding:0;
margin: 0;
}

.o2e-row ul li {
font-size: 24px;
  line-height: 22px;
  display: block;
  margin: 5px 0px;
  color: black;
}

.o2e-row ul li a{
text-decoration: none;
  color: black;
}

.o2e-row ul li i{
font-size: 13px;
line-height: 22px;
margin-right: 5px;
color: #ffbe18;
}

.checked,
.o2e-rating-menu i{
color: #ffbe18;
}

.see-all-btn button{
display: block;
  width: 100%;
  margin: 0 auto;
  background: #ffbe18;
  color: black;
  font-size: 12px;
  line-height: 30px;
  max-width: 85px;
  text-transform: capitalize;
  text-align: center;
  font-weight: bold;
}

.see-all-btn button:hover,
.see-all-btn button:active,
.see-all-btn button:focus,
.search-item-form .btn-submit:hover,
.search-item-form .btn-submit:active,
.search-item-form .btn-submit:focus{
background-color: #333333;
  color: #FFFFFF;
}

.side-bar-element{

}

.o2emenu-image img{
width: 100%;
display: block;
margin: 0 auto 15px;
}

.o2e-menu-content .o2emenu-delivery-info{}

.o2e-menu-content .o2emenu-delivery,
.o2e-menu-content .o2edevlivery-cost,
.o2e-menu-content .o2edevlivery-address{
font-size: 12px;
line-height: 20px;
}
.o2e-menu-content .o2edevlivery-cost{
padding-left: 30px;
background-size: 25px;
background-repeat: no-repeat;
background-position: left center;
background-image: url(./images/delevry-icon.png);
}
.o2e-menu-content .o2edevlivery-address{
padding-left: 30px;
background-size: 12px;
background-repeat: no-repeat;
background-position: 3px center;
background-image: url(./images/dark-map-icon.png);
}
.o2e-menu-content .o2edevlivery-time{
padding-left: 25px;
background-repeat: no-repeat;
background-position: left center;
background-image: url(./images/clock-icon.png);
background-size: 14px;
}
.o2e-menu-content .o2eitem-tag{
padding-left: 25px;
background-repeat: no-repeat;
background-position: left center;
background-image: url(./images/dish-icon.png);
background-size: 16px;
}
.o2e-item-rating{
color: #545454;
}

.o2emenu-userimage img{
width: 70px;
}
.o2e-pagination .page-link {
padding: 10px;
  line-height: 17px;
  color: #333333;
  border: 1px solid #dee2e6;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
align-items: center;
-ms-align-items: center;
justify-content: center;
-ms-justify-content: center;
border-radius: .25rem;
}
.o2e-pagination .page-item .page-link,
.o2e-pagination .page-item.active .page-link{
border: none;
}
.o2e-pagination .page-link:hover,
.o2e-pagination .page-item.active .page-link{
background-color: #333333;
color: #FFFFFF;
}
.o2e-pagination .page-link svg{
width: 12px;
}
.o2e-paymentmethod{
padding-top: 2rem;
padding-bottom: 2rem;
font-family: 'loewbold';
background-color: #333;
}
.o2e-paymentmethod h3 {
  font-size: 35px;
  color: #fff;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 0px;
}
.o2e-paymentmethod h3 span{
font-size: 14px;
}
.color-warning{
color: #fcc717;
}
.why-us-text h3{
color: #ff2000;
font-family: 'loewbold';
}
.why-us-text p{
color: #2f2b2a;
font-size: 16px;
font-family: 'loewmedium';
}
.o2e-how-it-work{
background-color: #e91e00;
}
.o2e-how-it-work h3,
.o2e-how-it-work h4,
.o2e-how-it-work h5{
font-family: 'loewbold';
}
.o2e-type-sms{
background-image: url(./images/sms-bg.png);
background-repeat: no-repeat;
background-size: 100%;
height: 429px;
}
.o2e-type-sms p{
color: #FFF;
font-size: 18px;
margin: 0px;
}
.o2e-footer-dark{
padding: 20px 0px;
background-color: #2f2b2a;
}
.o2e-menu-white .nav-item .nav-link{
border-right: 1px solid #fff;
color: #ffffff;
display: inline-block;
font-size: 12px;
font-weight: 500;
padding: 0rem .5rem 0rem .5rem;
line-height: 12px;
}
.o2e-menu-white .nav-item:last-child .nav-link{
border-right: none;
}
.o2e-footer-address h3{
font-family: 'loewbold';
}
.o2e-footer-address p {
  color: #fff;
  margin-bottom: 0;
  font-size: 12px;
}
.o2e-right-menu li{
color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  margin: .3rem 0px;
}
.o2e-right-menu li:last-child{
padding-left: .5rem;
}
.o2e-right-menu li a {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  padding: 0rem .5rem 0rem .5rem;
  border-right: 1px solid #fff;
}
.o2e-social-menu .nav-item{
margin: .5rem;
}
.o2e-social-menu .nav-item .nav-link{
  background-color: #999999;
  border-radius: 50%;
  color: #333;
  text-align: center;
  text-decoration: none;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 15px;
  margin: 0px;
  padding: 0rem;
  transition: all 0.2s ease-in-out 0s;
}
.footer-menu-mobile .accordion .btn{
font-size: 1.2rem;
}
.o2e-offer-card .card-header{
font-family: 'loewbold';
}
.o2e-offer-card .card-body{
font-size: 15px;
color: #444444;
}
.takeway-info-card h5{
font-size: 1.75rem;
font-family: 'loewheavy';
}
.takeway-info-card .card-body{
padding: 15px;
}
.nce-search-single-nav li{
display: flex;
font-size: 14px;
margin-bottom: 10px;
}
.nce-search-single-nav li .nce-img-icon{
margin-right: 10px;
}
.takeway-info-card{
padding: 20px;
}
.o2e-littleinfo h5{
font-size: 1.35rem;
font-family: 'loewbold';
}
.o2e-littleinfo p{
font-size: 13px;
}
.card-seprator{
padding-bottom: 20px;
border-bottom: 2px solid #CCC;
}
.o2e-mdia-row{
border-bottom: 1px solid #CCC;
}
.o2e-littleinfo .nav-tabs{
border-bottom: 0px;
}
.o2e-littleinfo .nav-tabs .nav-link{
border: 0px;
text-align: center;
font-size: 16px;
color: #000;
font-family: 'loewbold';
margin-bottom: 0px;
border-bottom: 2px solid #ccc;
}
.o2e-littleinfo .nav-tabs .nav-link:hover,
.o2e-littleinfo .nav-tabs .nav-link:focus,
.o2e-littleinfo .nav-tabs .nav-link.active{
border-bottom: solid 2px #ffcf3a;
}
.o2e-tabsmenu-title{
background-color: #b41502;
border-radius: .2rem;
}
.table.table-center tr th,
.table.table-center tr td{
text-align: center;
}
.table.table-center thead th{
border: 0px;
}
.table.table-center tr th:first-child,
.table.table-center tr td:first-child{
text-align: left;
}
.o2e-nce-sponsor-tag {
  background-image: url(./images/sponsor-tag-bg-1.png);
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: -6px;
  width: 111px;
  height: 108px;
  /* top: -5px; */
  top: -25px;
  z-index: 1;
  background-repeat: no-repeat;
  transform: rotate(45deg);
}
.takeway-info-card h5{
  padding-left: 60px;
}
.o2e-checkout-details .o2e-widget-title h6{
margin-bottom: 0px;
font-size: 14px;
}
.o2e-checkout-details .bg-black{
color: #FFF;
/*font-size: 13px;*/
background-color: #000;
}
.o2e-checkout-details .o2e-cart-content{
border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.o2echeckout-header h3{
color: #000;
font-size: 16px;
font-weight: 700;
font-family: 'loewbold';
}
.o2euser-details{
color: #5d5c5d;
}
.o2euser-details ul li{
margin-bottom: 2px;
font-family: 'loewmedium';
}
.o2e-collapse-row{
position: relative;
}
.o2e-collapse-row .btn{
position: relative;
padding-right: 33px !important;
}
.o2e-collapse-row .btn-light {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #e2e6ea;
}
.o2e-collapse-row .btn-light:hover {
  color: #212529;
  background-color: #cfd4d9;
  border-color: #cfd4d9;
}
.o2e-collapse-row .btn .o2e-icon{
width: 16px;
height: 16px;
position: absolute;
right: 15px;
top: 50%;
margin-top: -8px;
}
.o2e-collapse-row .btn .o2e-icon:before{
top: 50%;
right: 0px;
width: 16px;
height: 3px;
content: "";
display: block;
margin-top: -1.5px;
position: absolute;
background-color: #000;
}
.o2e-collapse-row .btn .o2e-icon:after{
top: 0px;
left: 50%;
width: 3px;
height: 16px;
content: "";
display: block;
margin-left: -1.5px;
position: absolute;
background-color: #000;
}
.o2e-collapse-row .btn[aria-expanded="true"] .o2e-icon:after{
display: none;
}
.o2e-collapse-row .card-body p:last-child{
margin-bottom: 0px;
}
.seprator{
border-right: 2px solid #00000080;
  vertical-align: middle;
}


.faq-page-header{
min-height: 164px;
background-image: url(./images/faq-page-header.jpg);
}
.inner-page-header .page-title h1{
color: #FFFFFF;
font-size: 32px;
font-weight: 700;
font-family: 'loewbold';
}
.inner-page-header .page-title p{
color: #FFF;
margin: 0px;
}
.free-consultation h2,
.top10Popular h2{
font-size: 32px;
font-weight: 700;
/*margin-bottom: 0px;*/
font-family: 'loewbold';
}
.top10Popular h2{
font-size: 38px;
}
.free-consultation p{
font-size: 16px;
}
.top10Popular p{
font-size: 14px;
}
.o2e-collapse-row .btn.accordion-arrow .o2e-icon:before,
.o2e-collapse-row .btn.accordion-arrow .o2e-icon:after{
display: none;
}
.o2e-collapse-row .btn.accordion-arrow{
border-width: 2px;
padding-right: 55px !important;
}
.o2e-collapse-row .btn.accordion-arrow[aria-expanded="true"]{
background-color: #f9b931;
border-color: #343233;
color: #343233;
}
.o2e-collapse-row .btn.accordion-arrow .o2e-icon {
  width: 50px;
  height: auto;
  position: absolute;
  right: 8px;
  top: 0px;
  margin-top: 0px;
  bottom: 0px;
  background-image: url(./images/accordion-arrow.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;

  /*-webkit-transition: all ease-in-out .3s;*/
  /*-moz-transition: all ease-in-out .3s;*/
  /*-o-transition: all ease-in-out .3s;*/
  /*transition: all ease-in-out .3s;*/
}
.o2e-collapse-row .btn.accordion-arrow[aria-expanded="true"] .o2e-icon {
  background-image: url(./images/accordion-arrow-active.png);
  /*-webkit-transition: all ease-in-out .3s;*/
  /*-moz-transition: all ease-in-out .3s;*/
  /*-o-transition: all ease-in-out .3s;*/
  /*transition: all ease-in-out .3s;*/
}
.row.row-cols-1.row-cols-md-3 > div{
display: inline-block;
}
.card-columns{
column-count: 2;
}
.card-columns > .card{
margin-bottom: 0px;
}

.trr-container{
  width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

/**************************************************
  MEDIA QUERIES
**************************************************/
@media (min-width: 767px){
.mobileview{display: none;}
.footer-main .footer-widget .widget-content, .footer-main .collapsing{height: auto !important;}
}
@media (min-width: 1200px){
.sidebar-content{width: 350px;}
}
@media (min-width: 992px){
.sidebar-content{max-width: 350px;}
.search-container {
  /* max-width: 960px !important; */
}

.w-card{
  width: 100% !important;
}

.w-210{
  /* width: 210px; */
}
.container{
  /* margin-right: 0 !important;
  margin-left: 0 !important; */
  /* padding-left: 35px !important;
  max-width: 100% !important; */
}
.trr-container{
  /* margin-right: 0 !important;
  margin-left: 0 !important; */
  padding-left: 35px !important;
  /* max-width: 100% !important; */
}

}
@media (max-width: 1199px){
.o2e-logo{max-width: 190px;}
.home-page-content .o2e-logo {max-width: inherit;}
/* .cart-wrapper{width: 45px;height: 30px;background-position: 5px center;background-size: 15px;border-radius: 3px;} */
.cart-count {padding-left: 1rem;}
.navbar-toggler {height: 28px;width: 34px;padding: 0.20rem .4rem;}
.navbar-toggler-icon{background-size: 20px;background-position: 0px -1px;}
}
@media (max-width: 991px){
.navbar{width: 100%;}
.header-nav .navbar-nav{border-radius: 0px;padding: .5rem 0rem;width: 100%;}
.navbar-light .header-nav .navbar-nav .nav-link{font-size: 13px;padding: .2rem .5rem;}
.btn-feel-eating, .btn-choose-loc{font-size: 11px;padding-right: 7px !important;}
.navbar-light .header-nav .navbar-nav .nav-link:hover,.navbar-light .header-nav .navbar-nav .nav-link.active{color: #ffcf3a;}
.o2e-menu-white .nav{justify-content: center;-ms-justify-content: center;}
.inner-header .navbar-toggler-icon { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;}
.inner-header .navbar-toggler{border-color: #FFF !important; overflow: hidden;}
.navbar-toggler{position: absolute;left: 15px;}
.card-columns{column-count: 1;}
}
@media (max-width: 767px){
.o2e-banner h2 {font-size: 1.5rem;font-family: 'loewheavy';color: #ec2134;}
.o2e-banner h5 {font-size: .9rem;color: #161616;}
.mobile-view-column{margin-top: 0px;position: absolute;bottom: 0px;left: 0;right: 0px;margin-right: auto;margin-left: auto;}
.search-location{background: transparent;}
.mobile-view-column .form-row{margin-right: auto;margin-left: 25%;background-color: transparent !important;max-width: 176px;}
.btn-submit{width: 100%;text-align: center;display: block;background: #FCC717;}
.menu-seprator:before{display: none;}
.search-location .form-control, .search-location  .btn-submit{color: #000;text-align: center;font-size: 12px;font-family: 'loewbold';}
.search-location .select-menu,.search-location .form-control{margin-bottom: 0px;}
.search-location .form-control::-webkit-input-placeholder {color: #000;}
.search-location .form-control:-ms-input-placeholder {color: #000;}
.search-location .form-control::placeholder {color: #000;}
.search-location .form-control, .search-location  .btn-submit{height: 35px;}
.o2e-menu-content .o2e-specialoffer{right: 7px;top: 90px;}
.footer-widget{margin-bottom: 20px;}
.order2Eat-logo{text-align: center;}
/*.header-nav{top: 35px;}*/
.o2e-header{padding: 15px 0px 20px;}
.o2e-logo {max-width: inherit;}
.home-page-content .o2e-logo {max-width: inherit;}
.btn-feel-eating, .btn-choose-loc{font-size: 10px;}
.o2e-mobile-tabsmenu{padding-bottom: 15px;display: block;position: fixed;top:0px;right:0px;bottom:0px;left:0px;padding-top: 15px;padding-right: 55px;z-index: 1;background-color: rgba(255,255,255,.97);-webkit-transition: all ease-in-out .2s;-moz-transition: all ease-in-out .2s;transition: all ease-in-out .2s;transform:translate(-767px);}
.o2e-mobile-tabsmenu.show{transform:translate(0px)}
.o2e-menu-tabs .nav-pills > .nav-item.menu-item, .o2e-menu-tabs .nav-pills > .nav-item > .nav-link{font-size: 10px;padding: .5rem;}
.vertical-nav-pills .nav-link{padding: .5rem 1rem;height: 35px;}
.o2e-mobile-tabsmenu > div{height: 100%;overflow-y: auto;display: block;}
.vertical-nav-pills{width: 100%;}
.search-item-form .btn-submit{display: inline-block;width: auto;}
.search-item-form .form-control{background-size: 15px;padding-left: 2rem;background-position: 8px center;}
.o2emenu-image{margin-bottom: 15px;}
.o2emenu-image img{width: 100%;}
#topCategoryItem .owl-nav button.owl-next{right: 5px;}
#topCategoryItem .owl-nav button.owl-prev {left: 5px;}
.desktopview{display: none;}
.o2e-banner{min-height: inherit;position: relative;}
.o2e-paymentmethod h3{font-size: 2.5rem;}
.o2e-type-sms{min-height: 250px;background-size: cover;background-position: right;height: auto;}
.footer-widget .widget-title{display: flex;color: #2f2b2a;font-size: 17px;cursor: pointer;margin-bottom: 0px;}
.footer-main{background: #FFFFFF;padding-top: 2rem;padding-bottom: .7rem;}
.widget-nav.contact-widget .nav-item, .widget-nav .nav-item .nav-link{color: #2f2b2a;}
.company-number{margin-top: 15px;}
.footer-bottom .row > div, .footer-bottom .o2e-social-menu{justify-content: center;-ms-justify-content: center;}
.justify-center.justify-center.justify-center{justify-content: center !important;}
.o2e-footer-address .o2e-right-menu{-ms-flex-pack: center !important;justify-content: center !important;}
  .o2e-how-it-work h5{font-size: 1.5rem;}
  .o2e-type-sms p{font-size: 14px;}
  .o2e-footer-address{display:none;}
  .section{padding-top: 1rem;padding-bottom: 1rem;}
  .content-header .title{display:none;}
  .search-navbar{display:none;}
  .change-location{display: none !important;}
  .search-cuisines{display: none !important;}
  .search-top-header{display: none !important;}
  .o2e-menu-content .o2e-row{-ms-flex-pack: start !important;justify-content: flex-start !important;-ms-flex-wrap: wrap !important;flex-wrap: wrap !important;}
  .o2e-menu-content .o2emenu-image{width: 100%;}
  .o2e-menu-content .o2emenu-delivery{width: 100%;} 
  .o2e-menu-content .o2emenu-name{width: auto;}
  .search-page-content .inner-page-header {background: #c1c1c1;}
  .header-search{background-color: #B41502;}
  .topcategory-item .card .card-title{text-align:center;width:100%;}
  .o2e-vertical-tabsmenu{background-color: #b41502;padding: 10px;border-radius: .25rem;}
  .inner-header{background-color: #b41502;}
  .inner-page-header{background-position: center;}
  .order2Eat-logo{margin: 0px auto;}
  .navbar-light .header-nav .navbar-nav .nav-link{padding-left: 0rem;padding-right: 0rem;color: #FFF;}
  .o2emobileviewbuttons{font-size: .85rem;}
  .takeway-info-card h5{font-size: 1.5rem;text-align: center;padding-left: 60px;}
  .desktoplogo{display: none;}
  .navbar-toggler{position: absolute;left: inherit;right: 15px;}
  .footer-text.text-center {font-size: 10px;}
  .o2e-termscondition {text-align: center;}
  .o2e-collapse-row .btn.accordion-arrow{font-size: 13px;padding-right: 35px !important;font-weight: 600;}
  .o2e-collapse-row .btn.accordion-arrow .o2e-icon {width: 30px;}
}

.price-alignment{
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #fdfdfd;
}

.modal-title{
  font-size: 17px;
  line-height: 1.59;
  color: #2f2b2a;
  font-weight: 400;

}

.modal-inner-childern-section{
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  padding: 10px;
  line-height: 1.59;
  width: 450px;
  border-radius: 0.3rem;
  margin-bottom: 5px !important;
  display: flex;
  flex-direction: column;
  height: 320px;
  overflow: auto;
}

.main-child-cont{
  display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.background-img{
  background-image: url('./images/minus.png');
}

.main-child-btn{
  display: flex;
    flex-direction: column;
    align-items: center;
}
.btn-mar{
  margin-bottom: 10px;
}

/* .modal-inner-childern-section p{
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0,0,0,.125);
} */

.modal-inner-section{
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  padding: 10px;
  line-height: 1.59;
  width: 450px;
  border-radius: 0.3rem;
  margin-bottom: 5px !important;
  display: flex;
  justify-content: space-between;
}

.modal-inner-section span, .modal-inner-childern-section span{
  font-weight: bolder;
  
}

.modal-container h6{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 10px;
}

.modal-inner-section{
  cursor: pointer;
}

.o2emenucart-btn-loader{
  width: 35px;
  height: 35px;
  background: yellow;
  background-image: url(./images/loading-waiting.gif);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.margin-form{
  padding-top: 30px;
}

.gif-container{
  width: 15px;
  height: 15px;
}

/* delivery late modal */
.delivery-modal-container{
padding-left: 60px;
padding-right: 60px;
padding-bottom: 25px;
}

.delivery-modal-container h3{
    padding: 15px;
    border-bottom: 1px solid lightslategrey;
}

.delivery-cont{
  display: flex;
  justify-content: center;
}

.button-container{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.button-container p{
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 !important;
  font-weight: bold;
  font-size: 18px;
}

.delivery-cont p{
  font-weight: bold;
    margin: 0;
    /* padding: 20
px
; */
    padding-bottom: 30px;
    padding-top: 20px;
    font-size: 18px;
}

.delivery-cont span{
  padding-top: 20px;
  padding-left: 10px;
  font-size: 18px;
}

.react-responsive-modal-modal{
  border-radius: 0.5rem;
}

/* delivery late modal end*/


/* takeaway error msg */
.takeaway-error{
  background-color: #ffbaba;
    color: #d8000c;
    font-weight: normal;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 5px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.o2e-changepasscode button, .add-to-cart-cont button{
  background-color: #ffcf3a;
    padding: 3px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all .15s ease-in-out;
}

.o2e-changepasscode input{
  font-weight: bold;
    border: 1px solid lightgrey;
    padding: 2px;
    margin-right: 5px;
    padding: 3px;
}

/* takeaway error msg */

input[type="radio"]{
  clip: inherit !important;
}

.add-to-cart-cont{
  display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.space-between{
  justify-content: space-between;

}



.flex-1{
  flex: 1;
}

.d-flex{
  display: flex;
}

.add-to-cart-cont p{
  margin-bottom: 0;
  color: #2f2b2a;
    font-size: 14px;
    font-weight: bold;
}

@media (max-width: 640px){
.mobile-view-column .form-row{margin-left: 25%;}
}
@media (max-width: 575px){
#o2ePCarousel .owl-nav button.owl-next {right: -10px;}
#o2ePCarousel .owl-nav button.owl-prev {left: -10px;}
#download-background{width: 100%;bottom: -50px;left: -60px;}
.o2e-mobile-tabsmenu{transform:translate(-575px);}
.menu-lists .menu-item{text-align: center;}
.menu-lists .menu-item span{display: block; font-size: 12px;}
.o2e-fdo .section-heading .title{font-size: 42px;}
.o2e-social-menu .nav-item {margin: .4rem;}
.search-menu-container{
  max-width: 100%;
  width: 100%;
}

.search-container{
  max-width: 100%;
  width: 100%;
}
.container{
  /* padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0 !important; */
  /* margin-left: 10px !important; */
}
.p-container{
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.p0-container{
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.pagination li{
  display: flex;
  flex-wrap: wrap;
  padding: 2px 5px !important;
}
}

.card.o2e-card.o2e-feedabelly.border-0.p-0.mb-3 p {
  text-align: justify;
}

@media (max-width: 481px){
.mobile-view-column .form-row{margin-left: 22%;}
.o2e-nce-sponsor-tag{position: absolute; left: 40px; top: -75px;}
.pl-80 h3{padding-left: 50px !important;}
.w-card{width: 100% !important;}
.h-card{height: 500px;}
.o2emenu-image{width: 50px !important;}
.row{display: flex !important; flex-wrap: wrap !important;}
.o2emenu-image.text-center.align-items-center{width: 100px !important;}
.search-item-form .form-control{background-color: #313131;}
.d-a{display: flex !important; flex-wrap: nowrap !important;}
.p-relative{
  /* position: relative !important;
  top: -102px;
  left: 12px; */
}
}

@media (max-width: 1439px) and (min-width: 1024px) {
  .m-w{
    width: 332px;
  }
}
@media (max-width: 1023px) and (min-width: 425px) {
  .pl{
    padding: 11px !important;
    padding-left: 32px !important;
  }
}
@media (max-width: 415px){
.mobile-view-column .form-row{margin-left: 18%;}
}
@media (max-width: 376px){
.mobile-view-column .form-row{margin-left: 15%;}

}
@media (max-width: 360px){
.mobile-view-column .form-row{margin-left: 15%;}
.o2e-logo {max-width: 200px;}
}
@media (max-width: 321px){
.mobile-view-column .form-row{margin-left: 10%;}
}

/************************Custom Css Starts***********/
.takeaway-search-bar{background-color: #000;}
.nce-search-single {
  border-bottom: 1px solid #ddd;
  display: inline;
  float: left;
  /* padding: 15px 40px 30px 90px; */
  margin-bottom: 35px;
  position: relative;
  width: 100%;
}

.nce-search-single-nav{
  padding: 0 !important;
}

.nce-order-item .nce-search-single {
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: 26px;
}

#nce-menu-item{
  background-color: #f8f6f6 ;
  border-color: #f8f6f6 ;
  color: #010101 !important;
  padding: 15px;
  padding-right: 0;
}

.width-large{
  width: 100%;
}

#nce-menu-item .row{
  width: 100%;
}

/* .top-rate{
  margin-top: 60px;

} */

section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

/* @media (max-width: 1519px){
.nce-search-single {
    padding: 15px 15px 30px 70px;
}
} */

/* @media (max-width: 1519px){
.nce-order-item .nce-search-single {
    padding-left: 20px;
}
} */

/* .nce-checkout{
  background-color: #fff !important;
} */


.nce-special-offer h2 {
  background-color: #ffcf3a;
  color: #2f2b2a;
  font-size: 24px;
  text-align: center;
  padding: 12px 0;
  margin-bottom: 16px;
}

.nce-special-offer {
  border: 1px solid #ddd;
  display: inline;
  float: left;
  width: 100%;
  margin-top: 18px;
}

.nce-special-offer p{
  padding-top: 30px;
  padding-bottom: 30px;
}

.display-none{
  display: none;
}

.o2e-changepasscode .link{
  cursor: pointer;
}

.nce-cart-head {
  background-color: #b41502;
  color: #ffc713;
  font-size: 20px;
  text-align: center;
  padding: 11px 0;
  margin-bottom: 20px;
}

.href{
    cursor: pointer;
    margin-bottom: 20px;
}

.btn-primary {
  background-color: #ffc713 !important;
  border: 1px solid #ffc713 !important;
  color: #000000 !important;
}

.btn-style{
  padding-left: 0 !important;
}

.error-msg{
  color: red;
  font-size: 12px;
}

.nce-search-review-link{
  cursor: pointer;
}

#loader_img{
  padding-left: 0 !important;
  padding-bottom: 10px;
}

.cart-link{
  text-decoration: none !important;
  color: #000;
}

/* .owl-item{
  width: 206px !important;
    margin-right: 20px
} */


#o2ePCarousel .owl-nav .owl-prev {
  left: -20px;
}
#o2ePCarousel .owl-nav .owl-next, #o2ePCarousel .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  display: block;
  margin-top: -20px;
  border-radius: 44px;
  background-color: #FFF;
  -webkit-box-shadow: 1px 1px 3px #ccc;
  -moz-box-shadow: 1px 1px 3px #ccc;
  -o-box-shadow: 1px 1px 3px #ccc;
  box-shadow: 1px 1px 3px #ccc;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

#o2ePCarousel .owl-nav .owl-next {
  right: -20px;
}



#catId_943{
  margin-top: 7px;

}

.owl-item img{
  object-fit: cover;

}

.owl-cat-img{
  object-fit: cover !important;
  object-position: center !important;
  width: 100% !important;
}

.top-img img{
object-fit: cover;
    object-position: center;
}


#o2e-cmp-carousel .owl-nav .owl-next {
  right: -10px;
}

#o2e-cmp-carousel .owl-nav .owl-prev {
  left: -10px;
}

#o2e-cmp-carousel .owl-nav .owl-next, #o2e-cmp-carousel .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  display: block;
  margin-top: 0px;
  border-radius: 0px;
  background-color: transparent;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.flex-custom{
  flex-wrap: wrap;

}

@media(max-width:400px){
  #o2ePCarousel .owl-nav .owl-next {
    right: -15px;
  }
  
  #o2ePCarousel .owl-nav .owl-prev {
    left: -15px;
  }

  #o2e-cmp-carousel {
    margin-top: -50px;
    padding: 0px 50px;
}
}


.btn-submit {
  color: #2f2b2a;
  border: none;
  height: 58px;
  font-weight: 600;
  background: #FCC717 !important;
}

@media(max-width:425px){
  .o2e-header-wrapper{
    height: 470px;
  }

  
  .mobile-view-column {
    margin-top: 0px;
    position: absolute;
    bottom: 120px;
    left: 0;
    right: 0px;
    margin-right: auto;
    margin-left: auto;
}
}

@media(max-width:375px){
  .o2e-header-wrapper{
    height: 420px;
  }

  
  .mobile-view-column {
    margin-top: 0px;
    position: absolute;
    bottom: 120px;
    left: 0;
    right: 0px;
    margin-right: auto;
    margin-left: auto;
}
}

@media(max-width:320px){
  .o2e-header-wrapper{
    height: 370px;
  }

  .mobile-view-column {
    margin-top: 0px;
    position: absolute;
    bottom: 110px;
    left: 0;
    right: 0px;
    margin-right: auto;
    margin-left: auto;
}
}

.why-us-text{
margin-top: 20px;
}

.accordion__button:before {
    display: none !important;
}

.accordion__button{
  background-color: transparent !important;
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.accordion__item + .accordion__item{
  border: none !important;
}

.accordion{
  border: none !important;
}

.accordion__panel{
  padding: 0 !important;
}

.add-border{
  background-color: #fdfdfd;
  border: 1px solid #e2e3e2;
}

.padding-top{
  padding-top: 7px;
}

.custom-link{
  text-decoration: none !important;
  color: black !important;
}

.custom-card-body{
  padding: 0 !important;
}

.custom-nce-checkout{
padding: 0;
margin: 0;
}

.custom-flex{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toggle-img{
  width: 30px;
  height: 30px;
}

.toggle-btn{
  background: transparent;
  border: none;
  position: absolute;
  right: 15px;
  top: 25px;
  z-index: 999;
}

@media(max-width:500px){
  .inner-search-container{
    padding-right: 0 !important;
  }
  .custom-flex{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .nce-search-single-nav li{
    margin-bottom: 15px;
  }

  .nce-search-single-nav li:first-child{
    margin-top: 30px;
  }
}

.hide{
  display: none !important;
}


@media (max-width: 767px){
.o2e-mobile-tabsmenu {
    padding-bottom: 15px !important;
    display: block !important;
    position: fixed !important;
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    padding-top: 15px !important;
    padding-right: 55px !important;
    z-index: 999999 !important;
    background-color: rgba(255,255,255,1) !important;
    transition: all ease-in-out .2s !important;
    transform: translate(0px) !important;
}
}


.custom-cat-btn{
margin-top: 13px;
}


@media (max-width: 767px){
.o2e-menu-content .o2emenu-name {
    padding-left: 0 !important;
}
}

@media (min-width: 320px){
  /* .h-card{height: 500px;} */
  .col-lg-9.col-md-12.order-md-2{
    padding-right: 0;
    padding-left: 0;
  }
  .col-md-9{padding-left: 30px !important;}
}

.link-color-footer{
color: #000;
}

.allergy-margin-top{
  margin-top: 18px;
}

/* .o2e-card .card-img{
width: 125px;
height: 125px;
} */

#o2e-cmp-carousel .owl-item img{
  width: 125px !important;
  height: 125px;
}

#o2e-cmp-carousel .o2e-cmp-item{
  background-color: transparent;
  box-shadow: none !important;
}

.nav-link{
  cursor: pointer;
}

.o2e-specialoffer {
  top: 21px;
  left: -18px;
  width: 108px;
  color: black;
  text-align: center;
  position: absolute;
  background-color: #ffbe18;
  transform: rotate(-40deg);
  font-size: 10px;
  line-height: 20px;
}

.error-input{
  color: red;
  text-align: center;
}


.nce-search-review-link{
  
    border-bottom: 1px solid #b7b6b6;
    color: #2f2b2a;
    display: inline-block;
    font-size: 14px;
    margin-top: 35px;
    line-height: 14px;
}


#nce-menu-item{
  color: #2f2b2a !important;
  font-weight: 400 !important;
}

.nce-search-single-review{
  text-align: center;

}


.header-links .navbar-expand-lg .navbar-nav{
  align-items: center;
  font-weight: 400;
}


.header-links .navbar-light .navbar-nav .nav-link{
  color: #fff !important;
  font-weight: 400;
  font-family:'loewmedium'
}

.header-links .nav-item:not(:last-child){
  border-right: 2px solid #fff;
  font-weight: 400;
}

.header-links .logout{
  border-right: 0 !important;
  font-weight: 400;
}

.header-links  .nav-link{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  font-weight: 400;
}

.header-links .navbar-nav{
  padding-top: 5px;
  padding-bottom: 5px;
  background: #B41502;
  margin-right: 10px;
  font-weight: 400;
}

.view-all-btn a{
  color : #dc3545 !important
}

#nce-header {
  background-color: #b41502;
  display: inline;
  float: left;
  width: 100%;
}

.nce-header-area {
  display: inline;
  float: left;
  width: 100%;
}

.nce-header-left {
  display: table;
  float: left;
  width: 100%;
  height: 100%;
}

.nce-logo {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.nce-headeer-middle {
  display: table;
  float: left;
  text-align: right;
  width: 100%;
  height: 100%;
}

.nce-headeer-middle h1 {
  color: #ffc713;
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 0;
  margin-right: 75px;
  display: table-cell;
  vertical-align: middle;
}

.nce-header-right {
  display: inline;
  float: left;
  width: 100%;
  text-align: right;
}

.nce-header-right h2 {
  color: #fff;
  font-size: 16px;
  margin-top: 32px;
}

.nce-change-location-link, .nce-change-location-link:hover, .nce-change-location-link:focus {
  color: #fff;
  font-size: 12px;
  border-bottom: 1px solid #fff;
}

.nce-breadcrumb {
  margin-top: 25px;
  margin-bottom: 5px;
}

.nce-breadcrumb li {
  color: #fff;
  display: inline-block;
  font-size: 12px;
  list-style: none;
}

#nce-search {
  background-color: #2f2b2a;
  display: inline;
  float: left;
  padding: 18px 0;
  width: 100%;
}

.nce-search-area {
  display: inline;
  float: left;
  width: 100%;
}

.nce-search-form {
  background-color: #3c3b3b;
  display: inline;
  float: left;
  padding-left: 30px;
  width: 100%;
  position: relative;
}

.nce-search-icon {
  background-image: url('https://www.order2eat.co.uk/public/front/assets/images/search-icon.png');
  position: absolute;
  left: 28px;
  top: 16px;
  height: 22px;
  width: 21px;
}

.nce-search-form input[type="text"] {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  width: 100%;
  height: 50px;
  padding-left: 42px;
  padding-right: 240px;
  outline: none;
}

.nce-submit-btn {
  background-color: #ffc713;
  border-radius: 8px;
  color: #000000;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 232px;
  font-weight: 600;
  outline: none;
  border: none;
}


#nce-header a{
  color: #fff !important;
}

.main-search-content{
  /* padding-top: 20px; */
  width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.nce-search-cuisines h3 {
  background-color: #ffc613;
  color: #595959;
  display: block;
  font-size: 20px;
  padding: 12px 22px;
  margin-bottom: 0;
}

.App > .search-container{
  max-width: 100% !important;
}



@media (min-width: 768px){
.search-container {
    /* max-width: 720px !important; */
}
.trr-container{
  /* padding: 0 !important; */
  /* margin-right: 0 !important; */
  padding: 50px;
  padding-top: 100px;
}
.container{
  /* padding: 0 !important; */
  /* margin-right: 0 !important; */
}
.w-card{
  width: 100% !important;
}
}

  @media (min-width: 1200px){
    .search-container {
        max-width: 1140px !important;
    }
    .trr-container{max-width: 1140px !important;}

    }

    @media (min-width: 1300px){
      .search-container {
          max-width: 1270px !important;
      }
      }

      .o2e-menu-container svg{
        color: #FFC613;
        margin-right: 12px;
      }

      .o2e-menu-container li{
        /* margin-bottom: 15px !important; */
        margin-bottom: 10px !important;
      }

      .o2e-row ul li a{
        /* font-size:fs 14px; */
        font-size: 12px;
        color: #2F2B2A;
      }

      .o2e-row ul li{
        color: #2F2B2A;
      }

      .o2emenu-name h3{
        margin-bottom: 20px;
      }

      .o2e-rating-menu svg{
        margin-right: 0px !important;
      }

      .nce-sponsor-tag {
        background-image: url('https://www.justorder2eat.co.uk/public/front/assets/images/sponsor-tag-bg-1.png');
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        position: absolute;
        left: -6px;
        width: 111px;
        height: 108px;
        top: -5px;
        background-repeat: no-repeat;
      }

      .nce-sponsor-tag span {
        -ms-transform: rotate(7deg);
        -webkit-transform: rotate(7deg);
        transform: rotate(-45deg);
        display: inline-block;
        margin-top: 33px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 10px;
        color: #000;
    }

    .pagination{
      justify-content: center;
    }

    @media (max-width: 767px){
.nce-hidden-mobile {
    display: none;
}
.nce-header-right {
  display: none;
}

.nce-header-left {
  text-align: center;
  padding: 10px 0;
}

    }

    .padding-right-header{
      padding-right: 16px !important;
    }

    .nce-cart-price-summary {
      
      width: 100%;
      padding: 30px 36px;
  }

  .nce-cart-price-summary p {
    font-size: 18px;
    margin-bottom: 12px;
}
  @media (max-width: 1519px){
.nce-cart-price-summary {
    padding: 16px 10px;
}
  }

  @media (max-width: 1199px){
.nce-cart-price-summary {
    width: 100%;
    padding: 10px 15px;
}
  }

  .o2e-changepasscode{
    font-size: .9rem !important;
    }


    .active-cat{
      background: #ffcf3a !important;
      color: #000000 !important;
    }


    .o2e-changepasscode{
      display: flex;
    }

/* 
     .navbar-expand-lg .navbar-collapse{
      display: flex !important;
    flex-basis: auto;
    } 
    .navbar-collapse{
      flex-grow: unset;
    }  */

    .header-cont{
      display: flex;
      justify-content: space-between;
    }

    .menu-container{
      display: flex;
    align-items: center;
    }

    .navbar-nav{
      flex-direction: row;
    }

    .navbar-ul{
      padding-top: 5px;
    padding-bottom: 5px;
    background: #B41502;
    margin-right: 10px;
    flex-direction: row !important;
    }

    .navbar-ul .nav-item:not(:last-child) {
      border-right: 2px solid #fff;
      font-weight: 400;
  }

  .navbar-ul .nav-link{
    color: #fff !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-weight: 400;
    font-family: 'loewmedium' !important;
  }
.tablet-show{
  display: none;
}

.header-cont{
  margin-bottom: 20px;
  margin-top: 20px;
}

@media(max-width:768px){

  .navbar-ul, .cart-wrapper{
    display: none !important;
  }

  .header-main-container{
    padding-top: 15px;
    padding-bottom: 15px;
    background: #B41502;
    margin-bottom: 20px;
  }

  .header-main-container-2{
    padding-top: 15px;
    padding-bottom: 15px;
    background: #B41502;
    /* margin-bottom: 20px; */
  }

  .tablet-hide{
    display: none;
  }

  .tablet-show{
    display: block;
  }
  .visibility-none{
    visibility: hidden;
  }

  .navbar-mobile-nav .nav-link{
    color: #fff !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-weight: 400;
    font-family: 'loewmedium' !important;
  }

  .navbar-mobile-nav .nav-item:not(:last-child) {
    border-right: 2px solid #fff;
    font-weight: 400;
}

.header-cont{
  margin-bottom: 0px;
  margin-top: 0px;
}

.navbar-mobile-nav{
  
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-top: 15px;
    transition: all ease-in-out 1s;

}

.logo-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-container {
  display: flex;
  align-items: self-start;
  padding-top: 15px;
}
.p-l{
  padding: 12px !important;
  padding-left: 35px !important;
}

}

@media(max-width:767px){
  .navbar-mobile-nav{
    flex-direction: column;
  }

  .navbar-mobile-nav .nav-item:not(:last-child) {
    border-right: 0px solid #fff;
    font-weight: 400;
    text-align: center;
    margin-bottom: 15px;
}

.logout{
  text-align: center;
}
}

@media(max-width:575px){
  
 
}

@media(max-width:500px){
  .visibility-none{
    display: none;
  }
  .logout{
    text-align: left;
    padding-left: 0 !important;
  }
  .navbar-mobile-nav .nav-item:not(:last-child) {
    text-align: left;
    padding-left: 0 !important;
}

.navbar-mobile-nav .nav-link{
  padding-left: 0 !important;
}
}

.custom-basket{
  display: flex;
}

.hide-nav{
  display: none;
}

.none{
  display: none;
}
@media(max-width:768px){
.hide-menu{
  display: none;
}
}


.btn-warning:hover {
  color: #212529;
  background-color: #ffc107 !important;
  border-color: #d39e00;
}

.o2emenucart-btn:hover{
  background-image: url(./images/plus-symbol-button\ \(2\).png);
}


.allergy-margin-top img{
  width: 100%;
}

.o2e-changepasscode{
  flex-direction: column;
}

.o2efoodallergy-card a{
  color: #000000;
}
#nec-short-cart-box {
  display: none;
}

.button-desktop{
  display: none !important;
}
@media (max-width: 767px){
  
.button-desktop{
  display: block !important;
}
#nec-short-cart-box {
    background-color: #2d3c4b;
    display: inline;
    position: fixed;
    top: -100%;
    left: 0;
    padding: 5px 0;
    right: 0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    z-index: 999;
}


.nec-short-cart-box-area {
  display: inline;
  float: left;
  text-align: center;
  width: 100%;
}

.nec-short-cart {
  background-color: #f9b931;
  display: inline-block;
  color: #fff;
  padding: 5px 8px;
  font-size: 14px;
  line-height: 20px;
}

.nec-short-cart-box-area a {
  margin: 0 5px;
}

.nec-short-price {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.nec-short-place-order-btn, .nec-short-place-clear-btn {
  background-color: #ffcf3a;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 10px;
  text-align: center;
  color: #000000;
}

.red-btn-disable{
  background-color: #b59c40;
}


.nec-short-cart-box-area > a:hover{
  text-decoration: none;
  color: #000000;
}

.o2e-cart-footerbtns{
  flex-direction: column;

}

.o2e-cart-footerbtns button{
  margin-left: 0 !important;
  margin-bottom: 10px;
}

.delivery-modal-container{
padding-right: 0;
padding-left: 0;
}

.react-responsive-modal-modal{
  width: 400px;
}

.modal-inner-section{
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

}

@media(max-width:500px){
  .search-container .col-lg-12{
    padding-right: 0 !important;
  }
  
  .react-responsive-modal-modal{
    width: 320px;
  }

  
.modal-inner-section{
  width: 100%;
}

.nce-submit-btn{
  width: 95px;
}

.nce-search-form input[type="text"]{
  padding-right: 10px;
}
}

@media(max-width:345px){
  .nec-short-place-order-btn, .nec-short-place-clear-btn {
    font-size: 13px;
    padding: 5px 5px;
}

.nec-short-price{
  font-size: 13px;
}
}

.nav-register a{
  color: #2f2e2e;
}

.o2e-discount-text{
  margin-bottom: 10px;
}

.nav-register a:hover{
  color: #2f2e2e;
  text-decoration: none;
}

.search-container .row{
  width: 100%;
}



/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */


.sreach_bar{
  background-color: black;
}
.text-yellow{
  color: #ffc613;
}
.change-location span {
  font-size: 16px;
  font-family: 'loewbold';
  padding-left: 20px;
  line-height: 18px;
  /* background: url(./images/dark-map-icon.png) no-repeat 0px center; */
  background: url(./images/dark-map-icon1.png) no-repeat 0px center;
  /* background-size: 20px 20px; */
  /* background-size: 15px 20px; */
}

.p-50{
  padding: 50px !important;
}

.pl-80{
  padding-left: 80px !important;
}


.custom-checkbox.rounded .custom-control-label::before {
  border-radius: 2rem !important;
  background: #000000;
  border-color: #000000;
}


.custom-checkbox .custom-control-label::before{
  /* display: none !important; */
  border-radius: 50px !important;
}

/* .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  top: -3px;
  left: 9px;
}

.custom-control-label::after{
  z-index: 999;
} */

.geekmark{
  background-color: black;
  z-index: 9;
}
input.chk-foods.chk-filters.black-check {
  background: black !important;
}
/* .widget-form .form-control-label{
  background-color: black  !important;
} */


.t-color h3{
  background-color: #ffc613 !important;
  color: #595959 !important;
}

.custom-control input {
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 999999;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

/* .custom-checkbox .custom-control input:checked~.custom-control-label::after {
  background-image: url("./images/download.svg") !important;
} */
.custom-checkbox .chk-foods:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox.position-absolute.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #000000;
  background-color: #000000;
}

.m-20{
  margin-top: -20px;
}

.fo-24{
  font-size: 28px;
}

.pagination{
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: space-evenly;
}

.pagination.active li{
  color: white;
  background-color: #333333;
  /* padding: 10px 15px; */
  border-radius: 2px;
}

.pagination li a{
  color: black;
  text-decoration: none;
}
.pagination li a:hover{
  background-color: #333333;
  padding: 12px 17px;
  border-radius: 4px;
  color: white;
}

.pagination li a:active {
  background-color: #333333;
  padding: 12px 17px;
  border-radius: 4px;
  color: white;
}
.justify-content-center{
  display: flex;
  justify-content: space-around !important;
}
.w-300{
  width: 300px;
}

.page-item.active .page-link{
  /* background-color: #333333; */
  /* padding: 12px 17px; */
  /* border-radius: 4px; */
  /* color: white; */
}
.active a{
  background-color: #333333;
  padding: 10px 17px;
  border-radius: 4px;
  color: white !important;
}


.custom-control-label::before {
  position: absolute !important;
  /* top: 0.25rem; */
  top: 4px !important;
  left: -24px !important;
  /* left: -1.5rem; */
  display: block !important;
  /* width: 1rem; */
  width: 16px !important;
  height: 16px !important;
  /* height: 1rem; */
  pointer-events: none !important;
  content: "" !important;
  background-color: black !important;
  border: #adb5bd solid 1px !important;
}

.custom-control-label::after {
  position: absolute !important;
  top: 4px !important;
  left: -24px !important;
  display: block !important;
  /* width: 1rem; */
  width: 16px !important;
  height: 16px !important;
  /* height: 1rem; */
  content: "" !important;
  background: 50% / 50% 50% no-repeat ;
  
}

.bg-yellow a{
  background-color: #ffc107 !important;
  padding: 0;
}


.bg-white.bg-white{
  /* background-color: white !important; */
  background-color: #e6e5e2 !important;
}

.fo-13{
  font-size: 13px;
}

.p-0{
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.p-15{
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.flex-column{
  flex-direction: column;
}

.bg-transparent{
  background-color: transparent !important;
}

.b-white{
  background-color: white !important;
}

.p-6{
  padding: 6px !important;
}

.mb-15{
  margin-bottom: 15px;
}

.tr{
  right: 3px !important;
  top: 6px !important;
}


.flex-w{
  flex-wrap: wrap !important;
}
.m-width{
  display: flex !important;
  max-width: 100% !important;
}
.justify-around.justify-around{
  justify-content: space-between !important;
}
.pt-0.pt-0{
  padding-top: 0 !important;
}


.pl-0{
  padding-left: 0;
}

.wh-116{
  width: 116px;
  height: 116px;
}
.fo-313{
  font-size: 13px !important;
}
.wd span svg {
  width: 16px;
  margin-bottom: -3px;
  margin-top: -9px;
}
.wd span{
  margin-left: 2px;
}
.b{
  background-color: #fdfdfd !important;
}

.fs{
  justify-content: flex-start;
}

.t-i{
  text-align: inherit !important;
}


.pl-12{
  padding-left: 20px !important;
}

.fo-7{
  /* font-size: .7rem; */
  font-size: 11.2px;
}
.fo-18{
  font-size: 20px;
}

.ta-c{
  text-align: center;
}

.bgc-n{
  background-color: white !important;
}

.fo-24{
  font-size: 24px !important;
}



.fo-12{
  font-size: 12px;
}


.font-2-8{
  font-size: 28px;
}

.fo-16{
  font-size: 16px !important;
}

.f-p{
  font-weight: 400 !important;
  padding: .375rem .75rem !important;
}


.fo-14{
  font-size: 14px !important;
}


.fo-1{
  font-size: 16px !important;
}

.md-1300{
  max-width: 1300px !important;
}

.fo-15{
  font-size: 15px;
}


.fo-26{
  font-size: 26px !important;
}


.fo-14{
  font-size: 14px;
}



.fo-19{
  font-size: 19px;
}

.price{
  font-size: 16px;
}

.bg-yellow a{
  color: #000 !important;
}


.menu-content-header .title{
  font-size: 28px;
  margin-bottom: 20px;
}

.pr-0{
  padding-right: 0 !important;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  }

  .disable-links {
    pointer-events: none;
}

  @media (max-width: 767px){
.nce-hidden-mobile {
    display: none !important;
}

.pr-0{
  padding-right: 0 !important;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
}

.o2emenu-addcart{
  align-items: center;
}
}

.text-right{
  text-align: right;
}

.o2euser-details ul li{
  font-size: 15px;
}

.display-block{
display: block !important;
}